import React from 'react'
import { Link, graphql } from 'gatsby'
import Customer from '../components/CustomerPreview'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import CTA from "../components/cta"

export default props => (

  <Layout>
    <SEO 
      title="Stöndum saman með ferðaþjónustunni" 
      description="Við höfum lagt af stað í verkefni sem miðar að því að safna saman öllum ferðaþjónustuaðilum í miðlægt markaðstorg/bókunarsíðu þar sem eftirtalin markmið eru höfð að leiðarljósi" />
    
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundPosition: `center center`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen center"
          style={{
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
            textShadow: `0px 3px 10px #3e3d40`,            
          }}
        >
          Stöndum saman og styðjum ferðaþjónustuna
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-4-tablet is-size-3-widescreen"
          style={{
            textShadow: `0px 3px 10px #3e3d40`,
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
            textAlign: `center`,
          }}
        >
         Hvetjum Íslendinga til að ferðast innanlands og upplifa allt það góða sem landið hefur að bjóða á Ferðalandinu - <a href="https://ferdalandid.is">ferdalandid.is</a>.
        </h3>
        <CTA />
      </div>
    </div>

    <section className="section column is-10 is-offset-1">
      <div className="content intro center">
        <h2 className="title">Það eru erfiðir tímar framundan - stöndum saman</h2>

        <p>Framundan eru líklega erfiðustu tímar sem ferðaþjónustan á Íslandi hefur upplifað. Við bindum þó vonir um það að samkomubanni og mögulegu útgöngubanni verði aflétt á næstu mánuðum. Það er nokkuð ljóst að ferðalög erlendis verði í algeru lágmarki o.þ.l. algert hrun í komu ferðamanna til landsins. Erum við því líklega að horfa á eitt stærsta ferðasumar meðal Íslendinga innanlands.</p>
      </div>   
    </section>

    <section className="section column is-12 padding-0">
      <div className="content customers intro">
       
      <div className="columns margin-above ">
            <div className=" column is-6">
            <h3>Látum hjólin snúast</h3>
            <p>Mikilvægast í framhaldinu eru að halda hjólum atvinnulífsins gangandi og er gjafakortið sem kynnt var af ríkisstjórn er gott skref í rétta átt til að hvetja landsmenn til að nýta sumarið og ferðast innanlands, nýta sér þá fínu gististaði sem eru í boði sem og þá fjölbreyttu afþreyingu sem okkar fallega land hefur upp á að bjóða.</p>
            </div>
            <div className="column is-6"> <h3>Ísland er frábært heim að sækja</h3>
        <p>Okkar tilfinning er sú að fáir á Íslandi átti sig á þeirri fjölbreyttu þjónustu sem er í boði og eflaust margir sem hefðu gaman af því að fara í hvalaskoðun, snjósleðaferð, íshellaskoðun og jöklagöngur, hestaferðir o.s.frv. Við viljum hvetja Íslendinga og aðra sem búa á landinu að upplifa landið í allri sinni dýrð. </p></div>
        </div>
        
      </div>  
      
       
    </section>

    <section className="section column is-10 padding-2 is-offset-1">      
      <div className="content center">
        <h3 className="is-size-4">Sýnum hvað Ísland hefur að bjóða</h3>
        <p>Við höfum lagt af stað í verkefni sem miðar að því að safna saman öllum ferðaþjónustuaðilum í miðlægt markaðstorg/bókunarsíðu þar sem eftirtalin markmið eru höfð að leiðarljósi</p>

        <div className="columns margin-above why-choose">
            <div className="title is-size-5 column is-4"><p>Vefurinn er markaðssettur til þeirra sem búa á Íslandi*</p></div>
            <div className="title is-size-5 column is-4"><p>Engin þóknunargjöld verða tekin fyrir sölur í gegnum vefinn** </p></div>
            <div className="title is-size-5 column is-4"><p>Ferðaþjónustuaðilar bjóða lægsta mögulega verð***.</p></div>
        </div>

         <p>* Innihald verður aðallega á íslensku en mögulega einnig á öðrum tungumálum <br />
            ** Færslugjöld og umsjónarþóknun fara beint í gegnum birgja <br />
            *** Hvert fyrirtæki stjórnar sínum verðum og þeim afslætti sem verður í boði
         </p>

         <p>Það kostar ekkert að taka þátt og er opið öllum fyrirtækjum í ferðaþjónustu.</p>

         <CTA />

      </div>
    </section>

    <section className="section column is-12 padding-0">
      <div className="content intro center customers">
        <h3 className="title">Hverjir eru að baki þessa verkefnis</h3>
        
        
        <p>Verkefnið er leitt af Getlocal ehf. en Getlocal er hugbúnaðarhús sem sérhæfir sig í þjónustu fyrir fyrirtæki í ferðaþjónustu.</p>
        <p>Síðastliðin ár hefur Getlocal verið að þróa vefsölukerfi sem í dag eru nýtt af ferðaþjónustufyrirtækjum í yfir 15 löndum og tengist beint við birgðakerfi (eins og Bókun). Hugbúnaður er tilbúinn til notkunar og teljum við okkur vera í þeirri stöðu að geta sett upp svona vef á skömmum tíma með litlum tilkostnaði.</p>
        
        <Link to="/about/" className="btn">Nánar um Getlocal</Link>

        <h3>Hverjir taka þátt?</h3>
        <p>Fjöldi fyrirtækja hefur lýst yfir samstöðu og áhuga á því að taka þátt í þessu verkefni.</p>
        
        <div className="columns is-multiline is-vcentered">
          
          <Customer imgSrc="/img/elding.png" altText="Elding" website="https://elding.is" />
          <Customer imgSrc="/img/grayline.png" altText="Gray Line" website="https://grayline.is" />
          <Customer imgSrc="/img/rss.png" altText="Reykjavik Sightseeing" website="https://rss.is" />
          <Customer imgSrc="/img/solhestar.png" altText="Sólhestar" website="https://solhestar.is" />
          <Customer imgSrc="/img/whale-safari.png" altText="Whale Safari" website="https://whalesafari.is" />  
          <Customer imgSrc="/img/mrpuffin.jpg" altText="Mr Puffin" website="https://puffintours.is" />
          <Customer imgSrc="/img/airportdirectlogo.jpg" altText="Airport Direct" website="https://airportdirect.is" />
          <Customer imgSrc="/img/happytours.png" altText="Happy Tours" website="https://happytours.is" />
          <Customer imgSrc="/img/whaleakureyri.png" altText="Akureyri Whale Watching" website="https://www.whalewatchingakureyri.is/" />  
          <Customer imgSrc="/img/localguide.png" altText="Local Guide Vatnajökull" website="localguide.is" />  
          <Customer imgSrc="/img/icecaveiceland.png" altText="Ice Cave Iceland" website="https://www.icecaveiniceland.is/" />  
          <Customer imgSrc="/img/blackbeach.png" altText="Black Beach Tours" website="https://blackbeachtours.is/" />  

          

         </div> 

         <Link to="/product#partners" className="btn">Sjá fleiri fyrirtæki</Link>
         

      </div>   
    </section>

    <section className="section column is-10 is-offset-1">
      <div className="content blog">
        <h2 className="title center">
         Fréttir og tilkynningar
        </h2>
                  
        <BlogRoll />

      </div>
    </section>

    
    </Layout>
  
)


export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 768, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    bokuntripadvisor: file(relativePath: { eq: "bokuntripadvisor.png" }) {
      ...fluidImage
    }
    sitebuilder: file(relativePath: { eq: "sitebuilder.jpg" }) {
      ...fluidImage
    }
    checkoutcart: file(relativePath: { eq: "checkoutcart.png" }) {
      ...fluidImage
    }
    onlinepayment: file(relativePath: { eq: "onlinepayment.png" }) {
      ...fluidImage
    }
    airportbookingform: file(relativePath: { eq: "airportbookingform.png" }) {
      ...fluidImage
    }
  }
`;
